<template>
  <div class="pageare">
    <titlebar :name="this.name" />
    <div class="overall">
      <div class="contain" v-for="(item, index) in list" :key="index" @click="order(item,index)">
        <div class="vertical">挂单中</div>
        <!-- <div class="verticalnull" v-if="item.type == 2">空</div> -->
        <div class="details">
          <div class="number">{{ index + 1 }}号</div>
          <div class="namepr">
            <div class="namepr_name">{{ item.desc }}</div>
            <div class="namepr_price">￥{{ item.total_price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
export default {
  components: {
    titlebar: titlebar,
  },
  data() {
    return {
      list: [],
      name: "", //传过来的标题
    };
  },
  methods: {
    // 跳转到取单页
    order(item, index) {
      if(this.name == 'toTakeOrders') {
        this.$router.push({ path: "/toTakeOrders", query: { name: "toTakeOrders", index } });
      }
    },
    // 取单-挂单列表
    getList() {
      this.$api.hangList({
        shop_id: localStorage.getItem('shop_id')
      }).then(res => {
        // res.data.reduce((total, item) => {
        //   return total + item.price
        // })
        this.list = res.data.reverse(); // 返回数据是相反的
        this.list.forEach(item => {
          item.total_price = item.info.reduce((total,val) => {
            return total + Number(val.total_price)
          }, 0);
          item.total_price = item.total_price.toFixed(2);
        })
      })
    }
  },
  created() {
    this.name = this.$route.query.name || ''; //拿到传过来的标题
    console.log("name:", this.name);
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.pageare {
  width: 100%;
  height: 100%;
  background: #f8f8f8;

.overall {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 3rem 0rem 3rem 3.5rem;
  // height: 94.9rem;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100% - 11.3rem);
  height: fit-content;
}

.contain {
  display: flex;
  width: 23%;
  height: 13.2rem;
  background: #ffffff;
  box-shadow: 0rem 0.4rem 20rem 0rem rgba(153, 153, 153, 0.2);
  margin-left: 2.5rem;
  margin-bottom: 3.4rem;
}
/* .contain:first-child {
	  margin-left: 0;
	} */
.details {
  //   width: 100%;
  width: 34rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 2.5rem;
  font-size: 2.8rem;
  font-weight: bold;
  color: #333333;
  line-height: 4rem;
}
.vertical {
  width: 7.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-lr;
  letter-spacing: 1rem;
  background: #1588F5;
  font-size: 2.4rem;
  font-weight: 400;
  color: #fff;
  padding-top: 1rem;
}
.verticalnull {
  width: 7.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  font-size: 2.4rem;
  font-weight: 400;
  color: #333;
}
.namepr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.namepr_name {
  width: 18.5rem;
  font-size: 2.4rem;
  font-weight: 400;
  color: #333333;
  line-height: 3.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.namepr_price {
  font-size: 2.4rem;
  font-weight: 500;
  color: #1588F5;
  line-height: 3.3rem;
  margin-right: 3.6rem;
}
.number {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: initial;
}
}
</style>
